import { Image } from "expo-image";
import { Dimensions, Platform, Pressable, Text, View } from "react-native";
import { useTailwind } from "tailwind-rn";
import React, { useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setstorestatus } from "../api";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsStoreActive,
  setSelectedStore,
} from "../utils/redux/slice/StoreSlice";
import { Menu, Modal, Portal, RadioButton } from "react-native-paper";
import StoreClosePopup from "./StoreClosePopup";
import Toast from "react-native-toast-message";
import { StyleSheet } from "react-native";
import { setShowLoader } from "../utils/redux/slice/LoaderSlice";

const StoreBtn = ({}: any) => {
  const tailwind = useTailwind();
  const dispatch = useDispatch();
  const selectedStore = useSelector((state) => state?.store?.selectedStore);
  const isStoreActive = useSelector((state) => state?.store?.isStoreActive);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const [menuVisible, setMenuVisible] = React.useState(false);
  const allStores = useSelector((state) => state.store.storeListData);
  const [checked, setChecked] = React.useState(selectedStore.storeId || "");
  const openMenu = () => setMenuVisible(true);
  const closeMenu = () => setMenuVisible(false);

  React.useEffect(() => {
    if ( selectedStore) {
      setChecked(() => selectedStore.storeId);
      // setcurrentStore(selectedStore.storeId);
    }
  }, [selectedStore, dispatch]);

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  const dispatchIsStoreActive = (message, success) => {
    if (success) {
      showToast({ type: "success", text: message });
    } else {
      showToast({ type: "error", text: message });
    }
    dispatch(setShowLoader(false));
    dispatch(setIsStoreActive(!isStoreActive));
  };

  const changeStoreStatus = () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        const today = new Date();
        dispatch(setShowLoader(true));
        setstorestatus(
          isStoreActive ? "0" : "1",
          today.toISOString(),
          isStoreActive ? checked : "",
          token,
          selectedStore?.storeId
        ).then((res) => {
          dispatchIsStoreActive(res.data.message, true);
        }).catch((err) => {
          dispatchIsStoreActive(err?.message, false);
        });
      }
    });

    hideModal();
  };

  const [modalChild, setModalChild] = useState(
    <StoreClosePopup
      dispatchIsStoreActive={dispatchIsStoreActive}
      hideModal={hideModal}
    />
  );

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
  };

  const modalStyles = {
    zIndex: 100,
    width: Platform.OS === "web" ? "500px" : "10px",
    marginHorizontal: "auto",
  };

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }

  const menuStyles = {
    zIndex: 100,
    width: 150,
    color: "black",
    top: Dimensions.get("window").width >= 640 ?81:201,
    right: Dimensions.get("window").width >= 870 ? 120 : "3%",
  };
  return (
    <Menu
      visible={menuVisible}
      onDismiss={closeMenu}
      contentStyle={{ backgroundColor: "white" }}
      style={menuStyles}
      anchor={
        <View
          style={[
            tailwind(
              `mt-4 sm:mt-0 flex relative rounded-[30px] py-2.5 px-5 border-[3px] justify-between items-center flex-row border-solid ${
                isStoreActive
                  ? "border-colorLimegreen_100"
                  : "border-colorTomato_100"
              } `
            ),
          ]}
        >
          <Portal>
            <Modal
              visible={visible}
              onDismiss={hideModal}
              contentContainerStyle={containerStyle}
              style={modalStyles}
            >
              {modalChild}
            </Modal>
          </Portal>
          <Pressable
            style={[tailwind("justify-end h-[27px] items-center flex-row")]}
            onPress={openMenu}
          >
            {/* <Image
            style={tailwind('w-[9px] h-[9px] hidden')}
            contentFit="cover"
            source={require("../assets/notification.png")}
          /> */}
            <Text
              style={[
                tailwind(
                  "ml-0.5 text-[18px] text-left font-bold text-colorGray_200 font-poppins-bold"
                ),
              ]}
            >
              {selectedStore?.storeId}
            </Text>
          </Pressable>
          <Pressable
            style={[tailwind("")]}
            onPress={() => {
              if (isStoreActive) {
                showModal();
              } else {
                changeStoreStatus();
              }
            }}
          >
            <View
              style={[
                tailwind(
                  `w-[42px] ml-[20px] items-center flex-row p-1 rounded-2xl ${
                    isStoreActive
                      ? "bg-colorLimegreen_100"
                      : "bg-colorTomato_100"
                  }`
                ),
              ]}
            >
              <View
                style={[
                  tailwind(
                    `w-[16px] h-[16px] rounded-full bg-white ${
                      isStoreActive ? "ml-auto" : "mr-auto"
                    } `
                  ),
                  styles.buttonShadow,
                ]}
              ></View>
            </View>
          </Pressable>
        </View>
      }
    >
      {allStores &&
        allStores?.map((item, index) => {
          return (
            <View
              key={`stores-${index}`}
              style={tailwind(
                "flex flex-row items-center text-white mb-2 w-[calc(100%-32px)]"
              )}
            >
              <RadioButton.Android
                value={item.storeId}
                status={checked === item.storeId ? "checked" : "unchecked"}
                onPress={() => {
                  setChecked(() => item.storeId);
                  closeMenu();
                  dispatch(setSelectedStore(item));
                }}
                color="blue"
              />
              <Text style={tailwind("font-poppins-regular text-black")}>
                {item.storeId}
              </Text>
            </View>
          );
        })}
    </Menu>
  );
};

const styles = StyleSheet.create({
  buttonShadow: {
    // Android shadow
    elevation: 5,
    // iOS and web shadow
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 8,
    // Apply extra styles for web if needed
    ...(Platform.OS === "web" && {
      boxShadow: "0px 4px 8px -2px rgba(0,0,0,1)",
    }),
  },
});

export default StoreBtn;
