import { Image } from 'expo-image';
import React, { useState } from 'react';
import { View, StyleSheet, Pressable, Platform, Dimensions } from 'react-native';
import { Provider as PaperProvider, Button, Text, Menu } from 'react-native-paper';
import { DatePickerModal, registerTranslation, en  } from 'react-native-paper-dates';
import { useDispatch, useSelector } from 'react-redux';
import { useTailwind } from 'tailwind-rn';
import { setEndDate, setStartDate } from '../utils/redux/slice/DateFilterSlice';

registerTranslation('en', en);

const DatePicker = () => {
  const st = useSelector((state) => state?.dateFilter.startDate);
  const end = useSelector((state) => state?.dateFilter.endDate);
  const [range, setRange] = useState({ startDate: parseLocaleDateString(st), endDate: parseLocaleDateString(end)});
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Today")
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const tailwind = useTailwind();
  const dispatch = useDispatch();

  const menuStyles = {
    zIndex: 100,
    width: 150,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "17%" : "17%",
    left: Dimensions.get("window").width < 840 ? "67%" : "72%",
  };

  const onDismiss = () => {
    setOpen(false);
  };

  const onConfirm = ({ startDate, endDate }) => {
    setOpen(false);
    setRange({ startDate, endDate });
    startDate = startDate.toLocaleDateString('en-US');
    endDate = endDate.toLocaleDateString('en-US');
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
  };

  function parseLocaleDateString(localeDateString) {
    const [month, day, year] = localeDateString.split('/').map(Number);
    return new Date(year, month - 1, day); // month is 0-based in JavaScript Date
  }

  const yesterdayDate = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    return [yesterday, yesterday];
  };
  const last7days = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 6);
    return [yesterday, currentDate];
  };
  const last30days = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 29);
    return [yesterday, currentDate];
  };
  const thismonth = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const startOfNextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const endOfMonth = new Date(startOfNextMonth.getTime() - 1);
    // console.log('startOfMonth,endOfMonth',startOfMonth,endOfMonth)
    return [startOfMonth, endOfMonth];
  };
  const lastmonth = () => {
    const currentDate = new Date();
    const startofpreviousMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const startOfcurrentmonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    const endOfpreviousMonth = new Date(startOfcurrentmonth.getTime() - 1);
    // console.log('startofpreviousMonth',startofpreviousMonth,'endOfpreviousMonth',endOfpreviousMonth)
    return [startofpreviousMonth, endOfpreviousMonth];
  };
  const ranges = {
    "All":  [new Date("2022-01-01"), new Date()],
    "Today":  [new Date(), new Date()],
    "Yesterday": yesterdayDate(),
    "Last 7 Days": last7days(),
    "Last 30 Days": last30days(),
    "This Month": thismonth(),
    "Last Month": lastmonth(),
    "Custom Range": "",
  };

  return (
    // <PaperProvider>
      <View style={tailwind('')}>
        <Menu
                visible={visible}
                onDismiss={closeMenu}
                
                contentStyle={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#ddd",
                }}
                style={menuStyles}
                anchor={
                  <View style={tailwind("flex flex-row justify-between border border-black rounded-lg relative")}>

                  <Button onPress={() => {
                    // setOpen(true)
                    openMenu();
                   }} mode="contained" style={tailwind("min-w-[240px] bg-white ")}>
                    <Text style={tailwind("text-black font-poppins-regular mr-auto")}>
                    {/* {range.startDate ? range.startDate : 'Not selected'} -  {range.endDate ? range.endDate : 'Not selected'} */}
                    {range.startDate ? range.startDate.toLocaleDateString('en-US') : 'Not selected'} -  {range.endDate ? range.endDate.toLocaleDateString('en-US') : 'Not selected'}
                    </Text>
                  </Button>
                  <Image
                        style={[tailwind(" w-[30px] h-[30px] absolute right-[8px] top-[5px]")]}
                        contentFit="contain"
                        source={require("../assets/calendar.svg")}
                      />
                </View>
                }
              >
                {Object.keys(ranges).map((item, index) => {
                  return (
                    <Pressable
                      key={"distribution__no_" + index}
                      onPress={() => {
                        setSelectedOption(() => item)
                        if(item === "Custom Range") {
                          setOpen(true);
                          closeMenu()
                          return;
                        }
                        const dates = ranges[item];
                        onConfirm({startDate: dates[0], endDate: dates[1]});
                        closeMenu();
                      }}
                      style={tailwind(
                        `flex w-full px-2 items-center justify-start hover:bg-gray-300 py-2 ${selectedOption===item?"bg-colorSkyblue_100 text-white":""}`
                      )}
                    >
                      <Text style={tailwind(`text-[12px] ${selectedOption===item?"text-white":"text-black"}`)}>
                        {item}
                      </Text>
                    </Pressable>
                  );
                })}
              </Menu>
 
        {/* <Text style={styles.label}>
          Start Date: {range.startDate ? range.startDate.toDateString() : 'Not selected'}
        </Text>
        <Text style={styles.label}>
          End Date: {range.endDate ? range.endDate.toDateString() : 'Not selected'}
        </Text> */}
        <DatePickerModal
          mode="range"
          visible={open}
          onDismiss={onDismiss}
          startDate={range.startDate}
          endDate={range.endDate}
          label= "Select Range"
          onConfirm={onConfirm}
          presentationStyle='formSheet'
          calendarIcon= {require('../assets/calendar.svg')}
          closeIcon= {require('../assets/-icon-close.png')}
          editIcon= {require('../assets/edit-icon.svg')}
        />
      </View>
    // </PaperProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  label: {
    marginTop: 20,
    fontSize: 16,
  },
});

export default DatePicker;
