import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
  Button,
  ScrollView,
} from "react-native";
import {
  addProduct,
  askuploadtype,
  editProduct,
  getSiteDetails,
  getproductlist,
  updateimagetoCdn,
} from "../api";
import * as yup from "yup";
import { useFormik } from "formik";
import { Image } from "expo-image";
import { useTailwind } from "tailwind-rn";
import { Icon, Menu, Modal, Portal, TextInput } from "react-native-paper";
import { Defs, FeColorMatrix, Filter, Path, Svg } from "react-native-svg";
import * as ImagePicker from "expo-image-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Toast from "react-native-toast-message";

// import { REACT_APP_SITE_NAME, REACT_APP_SITE_LINK , REACT_APP_ZONE} from "@env";

const AddProductForm = ({
  hideModal,
  storeData,
  setproductlist,
  grouparray,
  allergens,
  headerText,
  data,
}) => {
  const tailwind = useTailwind();
  const [loader, setLoader] = useState(false);
  const [file, setFile] = useState(null);
  const [imageAsset, setImageAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updateCategoryName, setUpdateCategoryName] = useState("");
  const [toggleUpdateCategoryInput, setToggleUpdateCategoryInput] =
    useState(false);
  const [allCategories, setAllCategories] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [siteDetails, setSiteDetails] = useState();
  const [visible, setVisible] = React.useState(false);
  const [langMenuVisible, setLangMenuVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const menuIcon = require("../assets/menu-down.svg");

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };


  const menuStyles = {
    zIndex: 100,
    width: 150,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "32%" : "35%",
    left: Dimensions.get("window").width < 840 ? "7%" : "12%",
  };
  const menuStyles2 = {
    zIndex: 100,
    width: 110,
    color: "#00b6d3",
    top: Dimensions.get("window").width < 840 ? "23%" : "27%",
    left: Dimensions.get("window").width < 840 ? "70%" : "79.5%",
    // right: Dimensions.get("window").width < 840 ? "70%" : "11%",
  };

  // const token = localStorage.getItem("token");

  const handleUpdateCategory = (event, updatedCategoryName) => {
    if (event.key === "Enter" && updatedCategoryName !== "") {
      // formik.setValues("productCategory", updateCategoryName);
      setUpdateCategoryName("");
      setToggleUpdateCategoryInput(false);
    }
  };

  const handleUpdateCategoryCheck = () => {
    if (updateCategoryName !== "") {
      // dispatch(addRobot(updateRobotName));
      // formik.setValues({productCategory: updateCategoryName});
      setUpdateCategoryName("");
      setToggleUpdateCategoryInput(false);
      // dispatch(getAllRobots());
    }
  };

  const uploadImage = (e) => {
    let file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setImageAsset(reader.result);
    };
  };

  const validationSchema = yup.object().shape({
    productName: yup.string().required("Product Name is required."),
    // spanishProductName: yup.string().required("Product Name is required."),
    productPrice: yup.string().required("Product Price is required."),
    productDescription: yup.string(),
    // spanishProductDescription: yup.string("Product Description is required."),
    productCategory: yup.string().required("Product Category is required."),
    productImage: yup.string(),
    // ingredients: yup.string(),
    // positionYaw: yup.string(),
    // status: yup.boolean(),
    // imageLocationUrl: yup.string(),
  });

  const editFn = async (values, token, selectedProduct) => {
    editProduct(
      selectedProduct.productId,
      values,
      selectedProduct.storeId,
      token,
      selectedLanguage
    )
      .then((res) => {
        setLoader(false);
        closeModal();

        getproductlist(selectedProduct.storeId)
          .then((res) => {
            // console.log('Product list response',res)
            setproductlist(res.data.message);
            setImageAsset(null);
            // formik.setValues("productImage", selectedProduct?.image ? selectedProduct.image : "")
            // navigate(`/items/${selectedProduct.storeId}`)
            // window.location.reload(false);
            showToast({
              type: "success",
              text: "Product updated successfully"})
          })
          .catch((err) => {
            showToast({ type: "error", text: err?.message });
            console.log("Error in Product list", err);
          });
      })
      .catch((err) => {
        setLoader(false);
        closeModal();
        // toast.error("Something went wrong while editing location!");
      });
  };

  const addFn = async (values, token) => {
    addProduct(values, storeData?.storeId, token, selectedLanguage)
      .then((res) => {
        setLoader(false);
        closeModal();
        getproductlist(storeData?.storeId)
          .then((res) => {
            setproductlist(res.data.message);
            setImageAsset(null);
            showToast({
              type: "success",
              text: "Product added successfully"})
          })
          .catch((err) => {
            showToast({ type: "error", text: err?.message });
            console.log("Error in Product list", err);
          });
      })
      .catch((err) => {
        setLoader(false);
        hideModal();
        console.error(err);
        showToast({ type: "error", text: err?.message });
        //   toast.error(err.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      productId: "",
      productName: "",
      productPrice: "",
      productDescription: "",
      productCategory: "",
      productImage: "",
      ingredients: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      AsyncStorage.getItem("token").then((token) => {
        if (
          !(token === null || token === "null" || token === undefined || !token)
        ) {
          if (imageAsset) {
            let bodyFormData = new FormData();
            askuploadtype(file?.mimeType, token)
              .then((result) => {
                bodyFormData.append("file", file);
                updateimagetoCdn(
                  result.data.data.uploadURL,
                  bodyFormData,
                  token
                )
                  .then((res) => {
                    values.productImage = res.data.result.variants[0];
                    if (data) {
                      editFn(values, token, data);
                    } else {
                      addFn(values, token);
                    }
                    setLoader(true);
                  })
                  .catch((err) => {
                    console.log("Error in Image to CDN Upload", err);
                    showToast({ type: "error", text: err?.message });
                  });
              })
              .catch((err) => {
                showToast({ type: "error", text: err?.message });
                console.log("Error in Ask Image Upload", err);
              });
            setLoader(true);
          } else {
            if (data) {
              editFn(values, token, data);
            } else {
              addFn(values, token);
            }
          }

          setLoader(true);
        }
      });
    },
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue(
        "productName",
        data?.name[selectedLanguage?.code || "en-GB"]
      );
      formik.setFieldValue(
        "productCategory",
        data?.category[selectedLanguage?.code]
      );
      formik.setFieldValue("productPrice", data?.price);
      formik.setFieldValue(
        "productDescription",
        data?.description[selectedLanguage?.code]
      );
      formik.setFieldValue("ingredients", data?.ingredients);
      formik.setFieldValue("productImage", data?.image);
    }
  }, []);

  useEffect(() => {
    let categories = grouparray?.map((groupedData) => groupedData[0]);
    setAllCategories(categories);
  }, [grouparray]);

  useEffect(() => {
    getSiteDetails(process.env.REACT_APP_SITE_NAME)
      .then((res) => {
        setSiteDetails(res.data.message);
        setSelectedLanguage(res.data.message.languages[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (data) {
      formik.setFieldValue("productName", data?.name[selectedLanguage?.code]);
      formik.setFieldValue(
        "productCategory",
        data?.category[selectedLanguage?.code]
      );
      formik.setFieldValue(
        "productDescription",
        data?.description[selectedLanguage?.code]
      );
    }
  }, [selectedLanguage]);

  const pickImage = async () => {
    if (imageAsset) {
      return;
    }

    if (Platform.OS === "web") {
      // For web, this will open the file input dialog.
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {

        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    } else {
      // For mobile, use ImagePicker to select from camera or gallery
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.canceled) {
        setImageAsset(() => result.assets[0].uri);
        setFile(() => result.assets[0]);
      }
    }
  };

  const removeImage = () => {
    if (formik.values.productImage !== "") {
      formik.setFieldValue("productImage", "");
    }
    setImageAsset(null); // Clear the image from state
    setFile(null); // Clear the image from state
  };

  const closeModal = () => {
    formik.setFieldValue("productName", "");
    formik.setFieldValue("productCategory", "");
    formik.setFieldValue("productPrice", "");
    formik.setFieldValue("productDescription", "");
    formik.setFieldValue("ingredients", []);
    formik.setFieldValue("productImage", "");

    hideModal();
  };

  return (
    <View style={[tailwind("h-full")]}>
      <View
        style={tailwind(
          "flex flex-row justify-between bg-colorSkyblue_100 p-5 items-center rounded-t-lg"
        )}
      >
        <Text
          style={tailwind("text-2xl text-white font-bold font-poppins-bold")}
        >
          {headerText}
        </Text>
        <Pressable onPress={() => closeModal()}>
          {/* <Icon source="close" size={28} color="white" /> */}
          <Image
            style={tailwind("w-[24px] h-[24px]")}
            contentFit="cover"
            source={require("../assets/-icon-close.png")}
          />
        </Pressable>
      </View>

      <ScrollView
        contentContainerStyle={tailwind("py-2")}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* product name */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Name <Text style={tailwind("text-red-500")}>*</Text>
          </Text>
          <View style={tailwind("flex flex-row items-center relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/userName-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 flex-1 bg-white text-gray-600 h-[38px] font-poppins-regular"
              )}
              error={formik.errors.productName ? true : false}
              textColor="#4b5563"
              placeholder="Product Name"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("productName")}
              name="productName"
              value={formik.values.productName}
            />
            {siteDetails && (
              <Menu
                visible={langMenuVisible}
                onDismiss={() => setLangMenuVisible(false)}
                contentStyle={{
                  backgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#00b6d3",
                }}
                style={menuStyles2}
                anchor={
                  <View
                    style={[
                      tailwind(
                        "w-full my-auto px-4 py-1.5 bg-colorSkyblue_100 rounded-md h-[38px]"
                      ),
                    ]}
                  >
                    <Pressable
                      style={tailwind("font-poppins-regular h-full")}
                      onPress={() => setLangMenuVisible(true)}
                    >
                      <Text
                        style={tailwind(
                          "font-poppins-regular border-b border-b-white text-sm text-white flex flex-row w-full justify-between items-center my-auto text-center"
                        )}
                      >
                        {selectedLanguage?.name}
                        <View
                          style={tailwind(
                            "flex flex-row items-center mb-2 sm:mb-0"
                          )}
                        >
                          <Image
                            style={tailwind(
                              `w-[13px] h-[7px] mb-[${
                                Platform.OS === "web" ? 4 : 7
                              }px]`
                            )}
                            contentFit="cover"
                            source={require("../assets/menu-down-white.svg")}
                          />
                        </View>
                      </Text>
                    </Pressable>
                  </View>
                }
              >
                {siteDetails?.languages?.map((language, index) => {
                  return (
                    <Pressable
                      key={"distribution__no_" + index}
                      onPress={() => {
                        setSelectedLanguage(language);
                        setLangMenuVisible(false);
                      }}
                      style={tailwind(
                        `flex w-full px-2 items-center justify-start rounded-md py-2`
                      )}
                    >
                      <Text style={tailwind("text-sm text-colorSkyblue_100")}>
                        {language?.name}
                      </Text>
                    </Pressable>
                  );
                })}
              </Menu>
            )}
          </View>
        </View>

        {/* product category */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Category <Text style={tailwind("text-red-500")}>*</Text>
          </Text>
          <View
            style={tailwind(
              "border-b border-colorSkyblue_100 w-full bg-white h-[38px] font-poppins-regular py-2"
            )}
          >
            <Menu
              visible={visible}
              onDismiss={closeMenu}
              contentStyle={{ backgroundColor: "white" }}
              style={menuStyles}
              anchor={
                <View style={tailwind("w-full my-auto")}>
                  <Pressable
                    style={tailwind("font-poppins-regular h-full ")}
                    onPress={openMenu}
                  >
                    <Text
                      style={tailwind(
                        "font-poppins-regular text-base text-gray-600 flex flex-row w-full justify-between items-center my-auto h-full"
                      )}
                    >
                      {formik.values.productCategory === ""
                        ? "Select Category"
                        : formik.values.productCategory}
                      <View
                        style={tailwind(
                          "flex flex-row items-center mb-2 sm:mb-0"
                        )}
                      >
                        <Image
                          style={tailwind(
                            `w-[15px] h-[9px] mb-[${
                              Platform.OS === "web" ? 3 : 6
                            }px]`
                          )}
                          contentFit="cover"
                          source={menuIcon}
                        />
                      </View>
                    </Text>
                  </Pressable>
                </View>
              }
            >
              {allCategories &&
                allCategories?.map((category, index) => {
                  return (
                    <Pressable
                      onPress={() => {
                        formik.setFieldValue("productCategory", category);
                        closeMenu();
                      }}
                      style={tailwind(
                        `${"text-gray-900"} flex w-full px-2 items-center justify-start rounded-md py-2 text-sm`
                      )}
                    >
                      <Text>{category}</Text>
                    </Pressable>
                  );
                })}
            </Menu>
          </View>
        </View>

        {/* product description */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Description
          </Text>
          <View style={tailwind("flex flex-row items-center  relative")}>
            <View
              style={tailwind(
                "absolute left-0 top-[7px] w-[30px] h-[30px] z-[100]"
              )}
            >
              <Image
                style={tailwind("w-[21px] h-[21px]")}
                contentFit="cover"
                source={require("../assets/desc-icon.svg")}
              />
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 w-full bg-white text-gray-600 font-poppins-regular"
              )}
              contentStyle={tailwind("p-0 pl-4 pt-2")}
              multiline={true}
              numberOfLines={2}
              textColor="#4b5563"
              placeholder="Product Description"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="text"
              onChangeText={formik.handleChange("productDescription")}
              name="productDescription"
              value={formik.values.productDescription}
            />
          </View>
        </View>

        {/* product price */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Price <Text style={tailwind("text-red-500")}>*</Text>
          </Text>
          <View style={tailwind("flex flex-row items-center  relative")}>
            <View style={tailwind("absolute left-0 top-[7px] z-[100] pl-2")}>
              <Text
                style={tailwind(
                  "text-center text-gray-600 text-base font-poppins-regular"
                )}
              >
                {storeData?.currency?.symbol}
              </Text>
            </View>
            <TextInput
              style={tailwind(
                "border-b border-colorSkyblue_100 pl-4 w-full bg-white text-gray-600 h-[38px] font-poppins-regular"
              )}
              error={formik.errors.productPrice ? true : false}
              textColor="#4b5563"
              placeholder="Product Price"
              placeholderTextColor="#6c7381"
              selectionColor="#000000"
              cursorColor="#000000"
              inputMode="numeric"
              keyboardType="numeric"
              textContentType=""
              onChangeText={formik.handleChange("productPrice")}
              name="productPrice"
              value={formik.values.productPrice}
            />
          </View>
        </View>

        {/* allergens */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Allergens
          </Text>
          <ScrollView
            horizontal
            contentContainerStyle={tailwind("flex flex-row items-center mt-2")}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            {allergens.map((ingredient, index) => {
              if (
                formik?.values?.ingredients?.find(
                  (match) => match.ingredient._id === ingredient._id
                )
              ) {
                return (
                  <Pressable
                    key={`ingredient__no__${index}`}
                    style={tailwind(
                      "flex flex-row w-8 h-8 border-[2px] mr-1 border-gray-300 rounded-full"
                    )}
                    onPress={() => {
                      formik.values.ingredients.push({
                        ingredient: ingredient,
                        name: {
                          [selectedLanguage?.code]: ingredient.name,
                        },
                        _id: ingredient._id,
                      });
                    }}
                    name={ingredient.name}
                  >
                    <Pressable
                      style={tailwind(
                        "absolute w-[17px] h-[17px] top-[-6px] right-[-7px] z-[100] rounded-full bg-[#0006]"
                      )}
                      onPress={() => {
                        formik.setFieldValue(
                          "ingredients",
                          formik.values.ingredients.filter(
                            (match) => match.ingredient._id !== ingredient._id
                          )
                        );
                      }}
                    >
                      <Image
                        style={[tailwind(" w-[17px] h-[17px]")]}
                        source={require("../assets/close-icon2.svg")}
                        alt={`remove-${index}`}
                        contentFit="cover"
                      />
                    </Pressable>
                    <View style={tailwind("mr-4 w-[28px] h-[28px]")}>
                      <Image
                        style={[tailwind("w-[28px] h-[28px]")]}
                        source={ingredient?.image}
                        alt={`ingredient__img__${index}`}
                        contentFit="cover"
                      />
                    </View>
                  </Pressable>
                );
              } else {
                return (
                  <Pressable
                    key={`ingredient__no__${index}`}
                    style={tailwind(
                      "flex flex-row w-8 h-8 border-[2px] mr-1 border-gray-300 rounded-full"
                    )}
                    onPress={() => {
                      let newArr = [
                        ...formik.values.ingredients,
                        {
                          ingredient: ingredient,
                          name: {
                            [selectedLanguage.code]: ingredient.name,
                          },
                          _id: ingredient._id,
                        },
                      ];
                      formik.setFieldValue("ingredients", newArr);
                    }}
                    name={ingredient.name}
                  >
                    <View style={tailwind("mr-4 relative w-[28px] h-[28px]")}>
                      <Svg height="0" width="0">
                        <Defs>
                          <Filter id="grayscale">
                            <FeColorMatrix
                              type="matrix"
                              values="0.33 0.33 0.33 0 0
                      0.33 0.33 0.33 0 0
                      0.33 0.33 0.33 0 0
                      0 0 0 1 0"
                            />
                          </Filter>
                        </Defs>
                      </Svg>
                      <Image
                        style={[
                          tailwind("absolute w-[28px] h-[28px]"),
                          { filter: "url(#grayscale)" },
                        ]}
                        source={ingredient?.image}
                        alt={`ingredient__img__${index}`}
                        contentFit="cover"
                      />
                    </View>
                  </Pressable>
                );
              }
            })}
          </ScrollView>
        </View>

        {/* Image Upload */}
        <View style={tailwind("py-2 px-5")}>
          <Text style={tailwind("font-poppins-medium text-base text-gray-500")}>
            Image
          </Text>
          <TouchableOpacity
            style={tailwind(
              "flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dotted rounded-lg cursor-pointer"
            )}
            onPress={pickImage}
          >
            {!imageAsset && formik.values.productImage === "" ? (
              <View>
                <Image
                  style={[tailwind(" w-[30px] h-[20px] mx-auto")]}
                  source={require("../assets/upload-icon.svg")}
                  alt={`image-upload`}
                  contentFit="cover"
                />
                <Text
                  style={tailwind(
                    "font-poppins-medium text-base text-gray-500"
                  )}
                >
                  Click here to upload
                </Text>
              </View>
            ) : (
              <View
                style={tailwind(
                  "relative h-full p-3 flex justify-center items-center"
                )}
              >
                <Image
                  style={[tailwind("w-[124px] h-[124px]")]}
                  source={
                    imageAsset
                      ? imageAsset
                      : formik.values.productImage !== ""
                      ? formik.values.productImage
                      : imageAsset
                  }
                  alt={`uploadedImage`}
                  contentFit="cover"
                />
                <Pressable
                  onPress={removeImage}
                  style={tailwind(
                    "absolute p-2 text-xl transition-all duration-500 ease-in-out bg-red-500 rounded-full outline-none cursor-pointer bottom-2 right-2 hover:shadow-md"
                  )}
                >
                  <Image
                    style={[tailwind(" w-[20px] h-[20px]")]}
                    source={require("../assets/delete-icon.svg")}
                    alt={`uploadedImage`}
                    contentFit="cover"
                  />
                </Pressable>
              </View>
            )}
          </TouchableOpacity>
        </View>

        <Pressable
          style={tailwind(
            "flex items-center justify-center px-8 py-2 mx-auto my-4 rounded-lg w-max bg-colorSkyblue_100 mx-auto"
          )}
          onPress={() => {
            formik.handleSubmit();
          }}
        >
          <Text
            style={tailwind(
              "font-poppins-semiBold text-center text-white uppercase "
            )}
          >
            {data ? "Update" : "Add"}
          </Text>
        </Pressable>
      </ScrollView>
    </View>
  );
};

export default AddProductForm;
