import {
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  StatusBar,
  Dimensions,
} from "react-native";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { useTailwind } from "tailwind-rn";
import { useEffect, useRef, useState } from "react";
import Container from "./Container";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo, useRoute } from "@react-navigation/native";
import BottomNavigation from "./BottomNavigation";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";
import AppHeader from "./AppHeader";
import { ActivityIndicator, Modal, Portal } from "react-native-paper";
import { getorderlist, getstorelist } from "../api";

import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedStore,
  setStoreListData,
} from "../utils/redux/slice/StoreSlice";
import { io } from "socket.io-client";
import { setNewOrdersCount } from "../utils/redux/slice/OrdersSlice";
import ConfirmationPopup from "./ConfirmationPopup";
import { Audio } from "expo-av";

// const SOCKET_URL = "http://localhost:4000/";
// const socket = io(process.env.SOCKET_URL);

const Layout = ({ children }) => {
  const showLoader = useSelector((state) => state.loader.showLoader);
  const [showSidebarMenu, setShowSidebarMenu] = useState(false);
  const selectedStore = useSelector((state) => state?.store?.selectedStore);
  const route = useRoute();

  const linkTo = useLinkTo();
  const tailwind = useTailwind();
  const dispatch = useDispatch();

  AsyncStorage.getItem("token").then((token) => {
    if (token === null || token === "null" || token === undefined || !token) {
      setTimeout(() => linkTo("/login"), 100);
    }
  });

  useEffect(() => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getstorelist(token)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              dispatch(setStoreListData(res.data.message));
              if (!selectedStore || !selectedStore.storeId) {
                dispatch(setSelectedStore(res?.data?.message[0]));
              }
            } else {
              dispatch(setStoreListData([]));
            }
          })
          .catch((err) => {
            console.log("Error in Store list", err);
          });
      }
    });
  }, [dispatch]);

  const fetchOrders = () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getorderlist(10, 1, token, selectedStore, dispatch)?.then((res) => {
          const latestNewOrders = res?.data?.order.filter(
            (order) => order?.statusCode <= 2
          );
          dispatch(setNewOrdersCount(latestNewOrders.length));
        });
      }
    });
  };

  useEffect(() => {
    fetchOrders();
  }, [selectedStore]);

  // useEffect(() => {
  //   if(hiddenRef.current){
  //     console.log(hiddenRef, 'hidden')
  //     hiddenRef?.current?.click()
  //   }
  // }, [hiddenRef])

  return (
    <SafeAreaView
      style={tailwind(
        "relative bg-gray-200 overflow-hidden h-full w-full flex-1 flex"
      )}
    >
      {/* this is the hidden button to enable audio play because on first time app opening audio is not playing because the user is not interacted with the ui */}
      {/* <Pressable style={tailwind('')} ref={hiddenRef} onPress={() => console.log('hidden pressed')}></Pressable> */}
      {showLoader && (
        <ActivityIndicator
          size="large"
          animating={showLoader}
          style={tailwind("absolute left-[50%] top-[50%] z-[150]")}
          color="#00b6d3"
        />
      )}
      {showLoader && (
        <View
          style={tailwind(
            "absolute h-screen w-screen opacity-70 bg-white z-[100]"
          )}
        ></View>
      )}
      {/* Menu Sidebar for Mobile */}
      <View
        style={tailwind(
          `z-[10] absolute flex flex-row left-0 top-0 w-full h-full ${
            showSidebarMenu ? "" : "hidden"
          }  sm:hidden`
        )}
      >
        <SidebarMobile />
        <Pressable
          style={tailwind(
            `w-[20%] xsm:w-[40%] h-full bg-white opacity-70 bg-gray-500`
          )}
          onPress={() => setShowSidebarMenu(false)}
        ></Pressable>
      </View>

      <View
        style={tailwind(
          `py-2.5 px-2 sm:px-4 w-full ${
            Dimensions.get("window").width < 870 && Platform.OS === "web"
              ? "h-[89%]"
              : "h-[91%]"
          } md:h-full`
        )}
      >
        {/* <View style={tailwind('py-2.5 px-2 sm:px-4 w-full h-[calc(100%-75px)] md:h-full')}> */}
        {/* Header */}
        <AppHeader setShowSidebarMenu={setShowSidebarMenu} />

        {/* Main Content */}
        <View style={[tailwind("flex-row h-[97%] md:h-[calc(100%-90px)]")]}>
          {/* Sidebar */}
          <View style={[tailwind("hidden md:flex md:mr-4"), styles.shadow]}>
            <Sidebar />
          </View>

          <Container children={children}></Container>
        </View>
        {/* </ScrollView> */}
      </View>
      <BottomNavigation />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000", // Shadow color
    shadowOffset: { width: 0, height: 0 }, // Offset for shadow
    shadowOpacity: 0.12, // Opacity of the shadow
    shadowRadius: 13, // Radius of the shadow blur
  },
  transitShadowBox: {
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  sidebar: {
    shadowRadius: 1,
    // elevation: 1,
    shadowOpacity: 1,
    shadowOffset: {
      width: 0,
      height: 39,
    },
    shadowColor: "rgba(0, 0, 0, 0)",
  },

  transit: {
    shadowRadius: 14,
    // elevation: 14,
  },
});

export default Layout;
