import { Image } from "expo-image";
import { DevSettings, Platform, Pressable, StyleSheet, Text, View } from "react-native"
import { useTailwind } from "tailwind-rn"
import { FontFamily } from "../GlobalStyles";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { useEffect, useState } from "react";
import ConfirmationPopup from "./ConfirmationPopup";
import { Modal, Portal } from "react-native-paper";
import { useSelector } from "react-redux";

const Sidebar = () => {
    const tailwind = useTailwind();
    const linkTo = useLinkTo();
    const route = useRoute();
    const newOrdersCount = useSelector((state) => state?.orders?.newOrdersCount);

    const [activeTab,setActiveTab] = useState(route.name);
    const activeImagePath = [require("../assets/Orders-Active.svg"), require("../assets/Stores-Active.svg"), require("../assets/Reports-Active.svg"), require("../assets/Ottobot-Active.svg")];
    const inActiveImagePath = [require("../assets/Orders-InActive.svg"), require("../assets/Stores-InActive.svg"), require("../assets/Reports-InActive.svg"), require("../assets/Ottobot-InActive.svg")];

    const [visible, setVisible] = useState(false);
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    const [name, setName] = useState("");

    useEffect(() => {
      AsyncStorage.getItem("name").then((value) => {
        setName(() => value)
      })
    },[])



  const handleLogout = async () => {
    await AsyncStorage.removeItem("token");
    setTimeout(() => linkTo("/login"), 100);
    hideModal();
  };

    useEffect(() => {
      setActiveTab(() => route.name)
    },[route])
    
    const handleNavigation = (destination) => {
      linkTo(`/${destination}`);
    }

    const containerStyle = {
      backgroundColor: "white",
      zIndex: 100,
      borderRadius: 10,
    };
  
    const modalStyles = {
      zIndex: 100,
      width: Platform.OS === "web" ? "500px" : "10px",
      marginHorizontal: "auto",
    };

    return (
        <View
              style={[
                tailwind(
                  "p-4 sm:p-0 h-full flex-[1_0_6%] mr-4 md:mr-0 min-w-[85px] max-w-full sm:max-w-[75px]"
                ),
                styles.sidebar,
              ]}
            >
              <Portal>
                <Modal
                  visible={visible}
                  onDismiss={hideModal}
                  contentContainerStyle={containerStyle}
                  style={modalStyles}
                >
                  <ConfirmationPopup
                    title={"Logout"}
                    onSubmit={handleLogout}
                    hideModal={hideModal}
                  />
                </Modal>
              </Portal>
              <View
                style={[
                  tailwind(
                    "flex justify-between items-center flex-col w-full h-full py-4 rounded-[10px] bg-colorWhite"
                  ),
                ]}
              >
                <View style={[tailwind("w-full h-[45%] flex justify-between md:block"),]}>
                  {/* Orders Icon */}
                  {/* <Pressable style={[tailwind(" w-full mb-4")]} onPress={() => {setActiveTab("Orders")}}> */}
                  <Pressable style={[tailwind(" w-full mb-4")]} onPress={() => {handleNavigation("orderDashboard")}}>
                    <View style={[tailwind("flex flex-row sm:flex-col w-full items-center")]}>
                     <View style={[tailwind("relative")]}>
                    <View style={[tailwind("hidden min-w-[24px] sm:flex justify-center items-center  max-h-[25px] absolute rounded-full p-1 bg-red-500 text-center right-[-10px] top-[-12px] z-[200]")]}>
                      <Text style={[tailwind("text-white font-poppins-bold text-[10px] text-center")] }>
                      {newOrdersCount}
                      </Text>
                      </View>
                      <Image
                        style={[
                          tailwind("z-0 h-[30px] w-[30px] md:h-[24px] md:w-[24px] overflow-hidden  mr-4 sm:mr-0"),
                        ]}
                        contentFit="contain"
                        source={activeTab==="OrderDashboard"?activeImagePath[0]:inActiveImagePath[0]}
                      />
                     </View>
                      <Text
                        style={[
                          tailwind(
                            `z-[1] tracking-[0.2px] text-left text-xl md:text-base ${activeTab==="OrderDashboard"?"text-colorSkyblue_100 font-bold font-poppins-bold":"text-colorDarkslategray_200 font-poppins-medium"}`
                          ),
                        ]}
                      >
                        Orders
                      </Text>
                      {
                        activeTab==="OrderDashboard" &&
                        <Image
                        style={[
                          tailwind(
                            "top-[-4px] absolute right-[-15px] w-[34px] h-[34px] z-[100] rounded-3xl"
                          ),
                        ]}
                        contentFit="contain"
                        source={require("../assets/frame-635.png")}
                      />
                      }
                    </View>
                  </Pressable>
                  {/* Stores Icon */}
                  <Pressable
                    style={[tailwind("flex flex-row sm:flex-col w-full items-center z-[1] mb-4 ")]}
                    onPress={() => {handleNavigation("stores")}}
                  >
                    <Image
                      style={[tailwind("  h-[30px] w-[30px] md:w-[24px] md:h-[24px] overflow-hidden mr-4 sm:mr-0")]}
                      contentFit="contain"
                      source={activeTab==="Stores"?activeImagePath[1]:inActiveImagePath[1]}
                    />
                    <Text
                      style={[
                        tailwind(
                          `font-[500] leading-6 tracking-[0.2px] text-xl md:text-base text-left  ${activeTab==="Stores"?"text-colorSkyblue_100 font-bold font-poppins-bold":"text-colorDarkslategray_200 font-poppins-medium"}`
                        ),
                      ]}
                    >
                      Stores
                    </Text>
                    {
                      activeTab==="Stores" &&
                      <Image
                        style={[
                          tailwind(
                            "top-[-4px] absolute right-[-15px] w-[34px] h-[34px] z-[100] rounded-3xl"
                          ),
                        ]}
                        contentFit="contain"
                        source={require("../assets/frame-635.png")}
                      />
                    }
                  </Pressable>
                  {/* Reports Icon */}
                  <Pressable style={[tailwind("relative w-full mb-4")]} onPress={() => {handleNavigation("reports")}}>
                    <View style={[tailwind(" flex flex-row sm:flex-col w-full items-center")]}>
                      <Image
                        style={[tailwind("  h-[30px] w-[30px] md:w-[24px] md:h-[24px] overflow-hidden mr-4 sm:mr-0")]}
                        contentFit="contain"
                        source={activeTab==="Reports"?activeImagePath[2]:inActiveImagePath[2]}
                      />
                      <Text
                        style={[
                          tailwind(
                            `font-[500] leading-6 tracking-[0.2px] text-xl md:text-base text-left text-colorDarkslategray_200  ${activeTab==="Reports"?"text-colorSkyblue_100 font-bold font-poppins-bold":"text-colorDarkslategray_200 font-poppins-medium"}`
                          ),
                        ]}
                      >
                        Reports
                      </Text>
                      {
                      activeTab==="Reports" &&
                      <Image
                        style={[
                          tailwind(
                            "top-[-4px] absolute right-[-15px] w-[34px] h-[34px] z-[100] rounded-3xl"
                          ),
                        ]}
                        contentFit="contain"
                        source={require("../assets/frame-635.png")}
                      />
                    }
                    </View>
                  </Pressable>
                  {/* Ottobots */}
                  <Pressable style={[tailwind("relative w-full mb-4")]} onPress={() => {handleNavigation("ottobots")}}>
                    <View style={[tailwind("flex flex-row sm:flex-col w-full items-center")]}>
                      <Image
                        style={[tailwind("  h-[28px] w-[30px] md:w-[24px] md:h-[24px] overflow-hidden mr-4 sm:mr-0")]}
                        contentFit="contain"
                        source={activeTab==="Ottobots"?activeImagePath[3]:inActiveImagePath[3]}
                      />
                      <Text
                        style={[
                          tailwind(
                            `font-[500] leading-6 tracking-[0.2px] text-xl md:text-base text-left text-colorDarkslategray_200  ${activeTab==="Ottobots"?"text-colorSkyblue_100 font-bold font-poppins-bold":"text-colorDarkslategray_200 font-poppins-medium"}`
                          ),
                        ]}
                      >
                        Ottobots
                      </Text>
                      {
                      activeTab==="Ottobots" &&
                      <Image
                        style={[
                          tailwind(
                            "top-[-4px] absolute right-[-15px] w-[34px] h-[34px] z-[100] rounded-3xl"
                          ),
                        ]}
                        contentFit="contain"
                        source={require("../assets/frame-635.png")}
                      />
                    }
                    </View>
                  </Pressable>
                </View>


                  <View style={tailwind("w-full")}>
                    {/* User Icon */}
                    <View
                    style={tailwind(
                        "flex items-center justify-center flex-row md:flex-col mb-4 "
                    )}
                    >
                    <Image
                        style={tailwind("w-6 h-6 mr-4 mb-0 md:mb-1 sm:mr-0")}
                        contentFit="contain"
                        source={require("../assets/user-icon2.svg")}
                    />
                    
                       <Text
                        style={[
                            tailwind(
                            "w-full leading-6 text-xl md:text-base font-medium text-center text-colorDarkslategray_300 font-poppins-medium"
                            ),
                        ]}
                        >
                        {name}
                        </Text>
                       {/* <Text
                        style={[
                            tailwind(
                            "md:hidden md:w-full leading-6 text-xl md:text-base font-medium text-center text-colorDarkslategray_300 font-poppins-medium"
                            ),
                        ]}
                        >
                        Profile
                        </Text> */}
                    </View>

                    {/* Logout */}
                    <Pressable
                    onPress={() => {
                       showModal();
                    }}
                    style={[tailwind("")]}
                    >
                    <View style={tailwind("flex flex-row sm:flex-col  sm:items-center w-full")}>
                        <Image
                        style={[tailwind("ml-[-5px] md:ml-0 mr-4 sm:mr-0  h-[30px] w-[30px] md:w-[24px] md:h-[24px]")]}
                        contentFit="contain"
                        source={require("../assets/group-37017.png")}
                        />
                        <Text
                        style={[
                            tailwind(
                            "md:w-full leading-6 text-xl md:text-base font-medium text-center text-colorDarkslategray_300 font-poppins-medium"
                            ),
                        ]}
                        >
                        Logout
                        </Text>
                    </View>
                    </Pressable>
                  </View>
              </View>
        </View>
    )
}

const styles = StyleSheet.create({
    transitShadowBox: {
      shadowOpacity: 1,
      shadowOffset: {
        width: 0,
        height: 39,
      },
      shadowColor: "rgba(0, 0, 0, 0)",
    },
    sidebar: {
      shadowRadius: 1,
      // elevation: 1,
      shadowOpacity: 1,
      shadowOffset: {
        width: 0,
        height: 39,
      },
      shadowColor: "rgba(0, 0, 0, 0)",
    },
  
    transit: {
      shadowRadius: 14,
      // elevation: 14,
    },
    
  });
  

export default Sidebar;