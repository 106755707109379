import AsyncStorage from "@react-native-async-storage/async-storage";
import { Image } from "expo-image";
import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { Checkbox, Modal, Portal, Text } from "react-native-paper";
import { useTailwind } from "tailwind-rn";
import { editstore, getstorelist, setstorestatus } from "../api";
import { useDispatch, useSelector } from "react-redux";
import StoreClosePopup from "./StoreClosePopup";
import {
  setSelectedStore,
  setStoreListData,
} from "../utils/redux/slice/StoreSlice";
import Toast from "react-native-toast-message";
import { useLinkTo } from "@react-navigation/native";
import { setShowLoader } from "../utils/redux/slice/LoaderSlice";
import EditStoreForm from "./EditStoreForm";
import { max } from "date-fns";

const StoresTable = ({ handleStoreClick, setRefresh }) => {
  const storeData = useSelector((state) => state.store.storeListData);
  const [hideWhenClosed, setHideWhenClosed] = useState([]);
  const selectedStore = useSelector((state) => state?.store?.selectedStore);
  const tailwind = useTailwind();

  useEffect(() => {
    setHideWhenClosed(() => storeData?.map((item) => item.hideWhenClosed));
  }, [storeData]);

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const showToast = ({ type, text }) => {
    Toast.show({
      type: type,
      text1: text,
    });
  };

  //variables for table width according to the width of the window to make it responsive across all devices
  const factor = Dimensions.get("window").width >= 870 ? 165 : 64;
  // const tableMaxWidth =
  //   Dimensions.get("window").width - factor >= 950
  //     ? Dimensions.get("window").width - factor
  //     : 950;
  const tableMaxWidth =
    Dimensions.get("window").width - factor >= 650
      ? Dimensions.get("window").width - factor
      : 650;

  console.log(tableMaxWidth, "tableMaxWidth");

  const getStores = async () => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        getstorelist(token)
          .then((res) => {
            if (res.data.status === 200 || res.data.status === "200") {
              dispatch(setStoreListData(res.data.message));
              if (
                !selectedStore ||
                (selectedStore?.storeId &&
                  selectedStore.storeId === res?.data?.message[0]?.storeId)
              ) {
                dispatch(setSelectedStore(res?.data?.message[0]));
              }
              dispatch(setShowLoader(false));
            } else {
              dispatch(setStoreListData([]));
              dispatch(setShowLoader(false));
            }
          })
          .catch((err) => {
            console.log("Error in Store list", err);
          });
      }
    });
  };

  const dispatchIsStoreActive = (message, success) => {
    getStores();
    if (success) {
      showToast({ type: "success", text: message });
    } else {
      showToast({ type: "error", text: message });
    }
  };

  const changeStoreStatus = (e, item, index) => {
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        dispatch(setShowLoader(true));

        const today = new Date();
        setstorestatus(
          "" + Math.abs(item.status - 1),
          today.toISOString(),
          item.status ? checked : "",
          token,
          item.storeId
        )
          .then((res) => {
            dispatchIsStoreActive(res.data.message, true);
          })
          .catch((err) => {
            dispatch(setShowLoader(false));
          });
      }
    });

    hideModal();
  };

  const handlehidewhenclose = (checked, data, index) => {
    let storeData = {};

    storeData.hideWhenClosed = checked;
    AsyncStorage.getItem("token").then((token) => {
      if (
        !(token === null || token === "null" || token === undefined || !token)
      ) {
        editstore(data.storeId, storeData, token)
          .then((res) => {
            showToast({ type: "success", text: res.data.message.message });
            setHideWhenClosed((prev) =>
              prev.map((item, idx) => {
                if (idx === index) {
                  return checked;
                }
                return item;
              })
            );
          })
          .catch((err) => {
            showToast({ type: "error", text: err.message });
          });
      }
    });
  };

  const [modalChild, setModalChild] = useState(
    <StoreClosePopup
      dispatchIsStoreActive={dispatchIsStoreActive}
      hideModal={hideModal}
    />
  );

  const containerStyle = {
    backgroundColor: "white",
    zIndex: 100,
    borderRadius: 10,
    // height: "100%",
  };

  const modalStyles = {
    zIndex: 100,
    maxWidth: Platform.OS === "web" ? "80%" : "10px",
    maxHeight: Dimensions.get("screen").width < 600 ? "74%" : "72%",
    margin: "auto",
  };
  // const modalStyles2 = {
  //   zIndex: 100,
  //   width: Platform.OS === "web" ? "500px" : "10px",
  //   marginHorizontal: "auto",
  //   maxHeight: Dimensions.get("screen").width < 600 ? "74%" : "72%",
  // };
  

  if (Platform.OS !== "web") {
    modalStyles.marginHorizontal = 10;
  }
  return (
    <View style={tailwind("w-full")}>
      <Portal>
        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
          style={modalStyles}
        >
          {modalChild}
        </Modal>
      </Portal>
      {storeData?.length > 0 ? (
        <>
          {/* Table */}

          <ScrollView
            horizontal
            style={tailwind("")}
            contentContainerStyle={[tailwind(``), { width: tableMaxWidth }]}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <View style={tailwind("w-full")}>
              {/* table header */}
              <View
                style={tailwind("bg-[#E9EFF6] border-b-0 flex flex-row w-full")}
              >
                <View style={tailwind("flex-1 flex min-w-[200px]  justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Name
                  </Text>
                </View>
                <View style={tailwind("w-[150px] flex justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Zone
                  </Text>
                </View>
                <View style={tailwind("w-[120px] flex justify-center p-4")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                    Owner
                  </Text>
                </View>
                <View style={tailwind("w-[80px]  flex  justify-center p-2")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    Actions
                  </Text>
                </View>
                <View style={tailwind("w-[36px] flex  justify-center p-2")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                  </Text>
                </View>
                <View style={tailwind("w-[150px] flex  justify-center p-2")}>
                  <Text
                    style={tailwind(
                      "text-colorSkyblue_100 text-base font-poppins-semiBold"
                    )}
                  >
                    {" "}
                  </Text>
                </View>
              </View>

              {/* rendering table rows from filteredorders */}
              {storeData?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={tailwind("border-b-0 flex flex-row w-full")}
                  >
                    <Pressable
                      style={tailwind("flex-1  min-w-[200px] flex flex-row p-4")}
                      onPress={() => handleStoreClick(item)}
                    >
                      <Image
                        style={tailwind("w-[60px] h-[60px] mr-4")}
                        contentFit="cover"
                        source={item.image}
                      />
                      <View
                        style={tailwind("flex flex-col flex-shrink my-auto")}
                      >
                        <Text
                          style={tailwind(
                            "text-colorSkyblue_100 text-base font-poppins-semiBold"
                          )}
                        >
                          {item.name && Object.values(item.name)[0]}
                        </Text>
                        <Text
                          style={tailwind(
                            "text-black text-sm font-poppins-regular"
                          )}
                        >
                          {item.description &&
                            Object.values(item.description)[0]}
                        </Text>
                      </View>
                    </Pressable>
                    <View
                      style={tailwind("w-[150px] flex  p-4 justify-center")}
                    >
                      <Text
                        style={tailwind(
                          "text-black text-sm font-poppins-regular"
                        )}
                      >
                        {item.location && Object.values(item.location)[0]}{" "}
                      </Text>
                    </View>

                    <View
                      style={tailwind("w-[120px] flex  p-4 justify-center")}
                    >
                      <Text
                        style={tailwind(
                          "text-colorSkyblue_100 text-sm font-poppins-regular"
                        )}
                      >
                        {item.storeManager.name
                          ? item.storeManager.name
                          : "Not Assigned"}
                      </Text>
                    </View>
                    <View style={tailwind("w-[72px] flex  p-2 justify-center")}>
                      <Pressable
                        style={[tailwind("")]}
                        onPress={(e) => {
                          if (item.status) {
                            setModalChild(
                              <StoreClosePopup
                                dispatchIsStoreActive={dispatchIsStoreActive}
                                hideModal={hideModal}
                                selectedStoreTemp={item}
                              />
                            );
                            showModal();
                          } else {
                            changeStoreStatus(e, item, index);
                          }

                          setRefresh((prev) => !prev);
                        }}
                      >
                        <View
                          style={[
                            tailwind(
                              `w-[42px] items-center flex-row p-1 rounded-2xl ${
                                item.status
                                  ? "bg-colorLimegreen_100"
                                  : "bg-colorTomato_100"
                              }`
                            ),
                          ]}
                        >
                          <View
                            style={[
                              tailwind(
                                `w-[16px] h-[16px] rounded-full bg-white ${
                                  item.status ? "ml-auto" : "mr-auto"
                                } `
                              ),
                              styles.buttonShadow,
                            ]}
                          ></View>
                        </View>
                      </Pressable>
                      {/* <Switch value={isSwitchOn} onValueChange={onToggleSwitch} theme={"version-3"} /> */}
                    </View>
                    <View style={tailwind("w-[36px] flex  p-2 justify-center")}>
                      <Pressable style={[tailwind("")]} onPress={(e) => {
                        setModalChild(() => (<EditStoreForm
                          hideModal={hideModal}
                          storeData={storeData}
                          selectedStore={item}
                        />))

                        showModal();
                      }}>
                        <Image
                          style={[tailwind(" w-[20px] h-[20px]")]}
                          contentFit="contain"
                          source={require("../assets/edit-icon.svg")}
                        />
                      </Pressable>
                      {/* <Switch value={isSwitchOn} onValueChange={onToggleSwitch} theme={"version-3"} /> */}
                    </View>

                    <View
                      style={tailwind(
                        "w-[150px] h-full pl-0 p-2 justify-center flex flex-row items-center font-poppins-regular relative"
                      )}
                    >
                      {/* <View style={tailwind(`${(Platform.OS === "web" )? "" : "border border-black absolute w-[24px] h-[24px] left-[6px]"}`)}></View> */}
                      <Checkbox.Android
                        status={hideWhenClosed[index] ? "checked" : "unchecked"}
                        color="blue"
                        // uncheckedColor="blue"
                        onPress={() =>
                          handlehidewhenclose(
                            !hideWhenClosed[index],
                            item,
                            index
                          )
                        }
                      />
                      <Text
                        style={tailwind(
                          "ml-2 font-poppins-regular flex-shrink text-black"
                        )}
                      >
                        Hide when Close
                      </Text>
                    </View>
                  </View>
                );
              })}
            </View>
          </ScrollView>
        </>
      ) : (
        <Text
          style={tailwind(
            "font-poppins-regular text-xl text-black text-center my-auto"
          )}
        >
          No Stores available
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonShadow: {
    // Android shadow
    elevation: 5,
    // iOS and web shadow
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 1,
    shadowRadius: 8,
    // Apply extra styles for web if needed
    ...(Platform.OS === "web" && {
      boxShadow: "0px 4px 8px -2px rgba(0,0,0,1)",
    }),
  },
});

export default StoresTable;
